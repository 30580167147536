<template>
  <div>
    <VueMarkdown >
      {{ content }}
    </VueMarkdown>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown-v2'
export default {
  components: {
    VueMarkdown
  },
  props:{
    content: {
      type: String,
      default: ""
    }
  }
}
</script>